@use '@trackback/styles/generic';
@use '@angular/material' as mat;
$material-icons-font-path: 'material-icons/iconfont/';
@use 'material-icons/iconfont/material-icons.scss';
$roboto-font-path: "roboto-fontface/fonts" !default;
@use "typeface-roboto/index.css";

@use 'styles/themes/treetable-widgets.theme' as treetable;
@use '../projects/trackback/ng-widgets/src/styles/themes/widgets.theme' as widgets;
@use '@angular/material/theming';

@import './styles/treetable.scss';
@import './styles/playlist.scss';

$tb-blue: (
        50 : #e8e9f0,
        100 : #c5c8da,
        200 : #9fa4c2,
        300 : #7880a9,
        400 : #5b6496,
        500 : #3e4984,
        600 : #38427c,
        700 : #303971,
        800 : #283167,
        900 : #1b2154,
        A100 : #939dff,
        A200 : #606fff,
        A400 : #2d41ff,
        A700 : #142aff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #ffffff,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

$tb-red: (
        50 : #f1e0e0,
        100 : #ddb3b3,
        200 : #c68080,
        300 : #af4d4d,
        400 : #9d2626,
        500 : #8c0000,
        600 : #840000,
        700 : #790000,
        800 : #6f0000,
        900 : #5c0000,
        A100 : #ff8d8d,
        A200 : #ff5a5a,
        A400 : #ff2727,
        A700 : #ff0e0e,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

$tb-primary: mat.define-palette($tb-blue);
$tb-accent: mat.define-palette($tb-red);
$tb-warn: mat.define-palette(mat.$red-palette);

$tb-light-theme: mat.define-light-theme((
  color: (
    primary: $tb-primary,
    accent: $tb-accent,
    warn: $tb-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
 ));

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
@include mat.all-legacy-component-themes($tb-light-theme);

@include widgets.widgets-theme($tb-light-theme);
@include treetable.treetable-widgets-theme($tb-light-theme);

@mixin style-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  // for login form
  .loginform {
      width: 100%;
  }

  tb-datepicker-field .mat-form-field {
      display: inline !important;
  }

  //for search box
  .cdk-overlay-pane-select-search {
      transform: none !important;
  }

  // For status dropdown  size
  div .mat-tab-body-content {
      width: 100% !important;
  }

  // audio popup
  .mat-drawer:not(.mat-drawer-side) {
      position: fixed;
  }

  .mat-drawer-backdrop {
      position: fixed !important;
  }

  // page shows double scrolling.
  .page-content-viewport {
      max-height: calc(100vh - 64px) !important;
    }

  mat-sidenav {
      .mat-drawer-inner-container {
          min-width: 280px;
      }
  }

  .icon_custom_mob button span mat-icon {
    color: mat.get-color-from-palette($primary, '400-contrast')
  }
  // playlist fab icon
  .icon_custom_mob button {
    position: fixed;
    top: 50px;
    right: 10px;
    background-color: mat.get-color-from-palette($accent, 500);
    z-index: 11;
  }
  .icon_custom button span mat-icon {
    color: mat.get-color-from-palette($primary, '400-contrast')
  }
  .icon_custom button {
    background-color: mat.get-color-from-palette($accent, 500);
    position: absolute;
    right: 62px;
    top: 41px;
  }

  .wordwrap{
      white-space: normal;
      word-wrap: break-word !important;
  }

  // underline
  .bottomline {
      border-bottom: 1px solid mat.get-color-from-palette($primary, '300-contrast');
  }

  .wordwrap-dealer {
      white-space: initial;
  }

  .mat-select-panel {
      max-height: 350px !important;
  }

  .mat-icon-button {
    &.cdk-program-focused .mat-button-focus-overlay {
      opacity: 0 !important;
    }
  }

  tb-texthtml table {
    background-color: mat.get-color-from-palette($primary, '400-contrast');
  }

  tb-email img {
      width: 100% !important;
  }

  // set the height for dialogueBox
  .mat-dialog-container {
    height: auto !important;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    // set the height for dialogueBox
    .mat-dialog-container {
      overflow: auto !important;
      max-height: 525px !important;
    }
  }

  #leads-page, #calls-page, #checkback-page {
      .mat-expansion-panel-body {
          background-color: mat.get-color-from-palette(mat.$grey-palette, 200);
          padding-top: 24px !important;
      }
  }

  .cursor-ponter {
    cursor: pointer;
  }

  tb-toolbar {
    border-bottom: 1px solid rgba(0,0,0,.12)
  }

}

.mat-card-header-text {
  overflow: hidden;
}

.mat-card-avatar {
  height: 40px;
  width: 40px;
  margin-right: 8px;
  border-radius: 50%;
  flex: 0 0 40px;
}

tb-text.mat-card-avatar {
  line-height: 40px;
}

@include style-theme($tb-light-theme);

.white-space-pre-line {
  white-space: pre-line;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.full-height {
  height: 100%;
}


$sizes: 10 11 12 14 16 18 20 24 28 32 40;

@each $size in $sizes {
  .font-size-#{$size} {
    font-size: #{$size}px;
  }
}
